import { useVariant } from '@unleash/proxy-client-react';
import { useSelector } from 'react-redux';

import { FEATURE_PDP_PAPER_CUTS } from 'constants/features';
import { selectIsFeaturePdpPaperCuts } from 'selectors/features';

export const TREATMENT_AFTERPAY_REWORDING = 'treatment-afterpay-rewording';

export const useFeaturePdpPaperCuts = () => {
  useVariant(FEATURE_PDP_PAPER_CUTS);

  const { isTreatmentPdpStyleUpdatesEnabled, isTreatmentAfterpayRewordingEnabled } = useSelector(selectIsFeaturePdpPaperCuts);

  return {
    isTreatmentPdpStyleUpdatesEnabled,
    isTreatmentAfterpayRewordingEnabled
  };
};
