import type { FormattedProductData } from 'reducers/detail/productDetail';
import type { EmptyObject } from 'types/utility';
import type { GenericSizeBiases } from 'types/product';

// corresponding mapping present at: https://code.amazon.com/packages/ZapposInfluencerServiceARestInterface/blobs/mainline/--/src/com/amazon/zapposinfluencerservicearest/model/SocialMediaProfileType.java
export enum ProfileType {
  INSTAGRAM = 'Instagram',
  YOUTUBE = 'Youtube',
  FACEBOOK = 'Facebook',
  TIKTOK = 'TikTok'
}
export enum ProfileValidationStatus {
  PENDING = 'PendingValidation',
  VALIDATED = 'Validated',
  DECLINED = 'Declined'
}
export enum InfluencerStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  NULL = 'null',
  UNKNOWN = 'UNKNOWN'
}

export enum InfluencerContentAPIAction {
  SHARE = 'share',
  HIDE = 'hide',
  SHOW = 'show',
  LIKE = 'like',
  UNLIKE = 'unLike'
}

export interface SocialMediaProfile {
  profileIdentifier: string;
  profileName: string;
  profileType: ProfileType;
  profileUrl: string;
  validationStatus: ProfileValidationStatus;
  canReapply: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface SocialMediaProfileRequestBody {
  profileName?: String;
  profileType: String;
  profileIdentifier?: String;
  oAuthCode: String;
  refreshToken?: String;
  tokenExpirationDate?: String;
  oAuthScope?: String;
  profileUrl?: String;
}

export interface InfluencerAppConfig {
  facebookClientAppId: string;
  InstagramClientAppId: string;
  googleClientAppId: string;
  TikTokClientAppId: string;
}

export interface ProductDetails {
  brandName: string;
  merchantId: string;
  productUrl: string;
  productId: number;
  color: string;
  imageId: string;
  price: string;
  productName: string;
  productData: FormattedProductData & { styleId: string } & { genericSizeBiases: {} | GenericSizeBiases | null };
  isProductDataAvailable: boolean;
  isOOS: boolean;
}

export interface ProductStyleDetails {
  [key: string]: ProductDetails;
}

export interface InfluencerShoppablePost {
  active: boolean;
  title: string;
  postId: string;
  description: string;
  mediaMetadata: InfluencerMediaMetadata[];
  influencerId: string;
  postType: string;
  likes: number;
  tags?: string[];
}

export interface SelectedReportDuration {
  label: string;
  value: string;
}

export interface HashTags {
  tag: string;
  tagUsageCount?: number;
}

interface FollowContent {
  customerFollowedStatus: boolean;
  influencerFollowersCount: number;
}

export interface InfluencerState {
  status: InfluencerStatus;
  socialMediaProfiles: EmptyObject;
  stylesList: string[];
  profileBio: string;
  profileHandle: string;
  name: string;
  isLoading: boolean;
  isInfluencer: boolean;
  influencerToken: string;
  appConfig: InfluencerAppConfig;
  report: EmptyObject;
  summaryReport: EmptyObject;
  collectionReport: EmptyObject;
  productReport: EmptyObject;
  creationDate: number;
  influencerCreationDays: number;
  displayReport: boolean;
  displayCollectionReport: boolean;
  displayProductReport: boolean;
  displaySummaryReport: boolean;
  isProfileComplete: boolean;
  draft: InfluencerProfileDraft;
  influencerId: string;
  productStyleDetails: ProductStyleDetails;
  shoppablePost: InfluencerShoppablePost;
  productSearchResults: EmptyObject;
  apiStatus: string;
  scrutinizerScanStatus: ScrutinizerScanState;
  selectedReportDuration: SelectedReportDuration;
  currentCollectionReportPage: number;
  currentProductReportPage: number;
  availableHashtags?: HashTags[];
  followContent: FollowContent;
  availableHashtagsLoaded?: boolean;
  showInfluencerHub: boolean;
}

type ShareSocialLinksInfluencerKeys = 'status' | 'influencerToken';

export type ShareSocialLinksInfluencerProps = Pick<InfluencerState, ShareSocialLinksInfluencerKeys>;

export interface InfluencerProfileDetails extends InfluencerProfileBio {
  profileHandle?: string;
}

export interface InfluencerProfileBio {
  name?: string;
  stylesList?: string[];
  profileBio?: string;
}

export interface InfluencerProfileDraft {
  data: InfluencerProfileBio;
  save: boolean;
}

export interface HeaderButton {
  css: string;
  text: string;
  onClick: () => void;
}

/* Influencer Content starts */

// corresponding mapping present at https://code.amazon.com/packages/ZapposInfluencerContentService/blobs/mainline/--/src/com/amazon/zapposinfluencercontentservice/constants/ContentTypes.java
export enum InfluencerContentType {
  SHOPPABLE_PHOTO = 'shoppablePhoto',
  SHOPPABLE_VIDEO = 'shoppableVideo',
  PROFILE_IMAGE = 'profileImage',
  COVER_IMAGE = 'coverImage',
  BRAND_SHOPPABLE_PHOTO = 'brandShoppablePhoto',
  BRAND_SHOPPABLE_VIDEO = 'brandShoppableVideo',
  COLLECTION = 'collection'
}

export interface ProductMetadata {
  styleId: string;
  merchantId: string;
  tagCoordinateX: number;
  tagCoordinateY: number;
}

export enum ModalType {
  DISCARD_POST,
  DELETE_POST
}

export enum ContentState {
  PENDING = 'pending',
  DECLINED = 'declined',
  DRAFT = 'draft',
  APPROVED = 'approved',
  UNAPPROVED = 'unapproved',
  SUBMITTED = 'submitted',
  HIDDEN = 'hidden',
  PUBLISHED = 'published'
}

export enum AlertState {
  NONE,
  DRAFT,
  APPROVED,
  PENDING,
  PENDING_PHOTO_ONLY,
  SUBMIT_PHOTO_ONLY,
  SUBMIT,
  DECLINED,
  NOT_ACTIVE,
  HIDDEN,
  PREVIEW,
  PRODUCT_REMOVED
}

export enum AlertBG {
  NONE,
  YELLOW,
  RED,
  BLUE_WHITE,
  BLUE,
  GREEN
}

export enum AlertIcon {
  NONE,
  BOX,
  SUCCESS,
  QUESTION,
  DECLINED,
  HIDE,
  LAYOUT,
  DISABLED
}

export type NonEmptyArray<T> = [T, ...T[]];
export interface InfluencerMediaMetadata {
  mediaId: string;
  mediaExtension: string;
  mediaType: string;
  mediaUrl: string;
  productMetadata?: NonEmptyArray<ProductMetadata>;
  approved?: boolean;
  createdAt?: number;
}

export interface InfluencerContent {
  contentId: string;
  contentType: string;
  title: string;
  description: string;
  influencerId: string;
  state: string;
  active: boolean;
  mediaMetadata: InfluencerMediaMetadata[];
  createdAt: number;
  updatedAt: number;
  likes: number;
  additionalMetadata?: any; // To support any adhoc data from backend
}

export interface InfluencerContentSlotData {
  slotPositions: number[];
  rowPositions: number[];
}

export interface ContentImage {
  contentId: string;
  imageId: string;
  extension: string;
  status: string;
}

export enum VideoState {
  CREATED = 'CREATED',
  PUBLISHING = 'PUBLISHING',
  FAILURE = 'FAILURE'
}

export enum ScrutinizerScanState {
  UNKNOWN = 'UNKNOWN',
  IN_PROGRESS = 'In Progress', // mapping backend value
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

//Selectors for the activeTab value on Influencer Hub
export enum ActiveNavTabs {
  PROFILE,
  MANAGE_CONTENT,
  INSIGHTS,
  ACCOUNT
}

/* Influencer Content ends */
