import queryString from 'query-string';

import { prependAppRoot } from 'history/AppRootUtils';
import { LAST_7_DAYS, REPORT_TOGGLE_OFF_ERROR } from 'constants/influencerReporting';
import { filterProfiles, getS3KeyForVideoUpload } from 'helpers/InfluencerUtils';
import { fetchLandingPageInfo } from 'actions/landing/landingPageInfo';
import {
  FETCH_INFLUENCER_COLLECTION_REPORT,
  FETCH_INFLUENCER_PRODUCT_REPORT,
  FETCH_INFLUENCER_SUMMARY_REPORT,
  IDENTIFIER_TYPE_PROFILE_HANDLE,
  INFLUENCER_ELIGIBILITY_PAGE,
  INFLUENCER_ENROLL_DECLINE,
  INFLUENCER_ENROLL_PENDING,
  INFLUENCER_ENROLL_SUCCESS_PAGE,
  INFLUENCER_HUB_PAGE,
  INFLUENCER_LANDING_PAGE,
  INFLUENCER_SIGNUP_PAGE,
  SCRUTINIZER_SCAN_STATUS_GET_API_POLL_DELAY,
  VIDEO_GET_API_POLL_DELAY
} from 'constants/influencerPages';
import { trackError } from 'helpers/ErrorUtils';
import { authenticationErrorCatchHandlerFactory, setError } from 'actions/errors';
import { INFLUENCER_HOME_PAGE } from 'constants/amethystPageTypes';
import {
  CLEAR_INFLUENCER_REPORTS,
  ENROLL_INFLUENCER_SUCCESS,
  REQUEST_INFLUENCER_DETAILS,
  SET_DOC_META_INFLUENCER_LP,
  SET_DOC_META_SHOPPABLE_POST,
  SET_DOC_META_STOREFRONT,
  SET_DOC_META_STYLE_FEED,
  SET_INFLUENCER_APP_CONFIG,
  SET_INFLUENCER_BIO,
  SET_INFLUENCER_COLLECTION_BANNER,
  SET_INFLUENCER_COLLECTION_REPORT,
  SET_INFLUENCER_DETAILS,
  SET_INFLUENCER_DRAFT,
  SET_INFLUENCER_NAME,
  SET_INFLUENCER_PRODUCT_REPORT,
  SET_INFLUENCER_PROFILE_HANDLE,
  SET_INFLUENCER_REPORT,
  SET_INFLUENCER_REPORT_PERIOD_SELECTION,
  SET_INFLUENCER_REPORT_VIEW,
  SET_INFLUENCER_STYLES,
  SET_INFLUENCER_SUMMARY_REPORT,
  SET_INFLUENCER_SYMPHONY_SLOTS,
  SET_INFLUENCER_TOKEN,
  SET_IS_INFLUENCER_REPORT_LOADING,
  SET_IS_INFLUENCER_STATUS,
  SET_NEW_COLLECTION_CREATED,
  SET_SHOPPABLE_POST_CREATION_SOURCE,
  SET_SHOW_INFLUENCER_HUB,
  TOGGLE_INFLUENCER_SAVE_DRAFT
} from 'constants/reduxActions';
import { redirectTo } from 'actions/redirect';
import { setApiStatus } from 'actions/influencer/shoppablePost';
import { track } from 'apis/amethyst';
import { evEnrollInfluencer, evLandingPageInfluencer } from 'events/influencer';
import { InfluencerStatus, ProfileType, ProfileValidationStatus, ScrutinizerScanState, VideoState } from 'types/influencer';
import { getLandingPageInfo } from 'apis/zcs';
import {
  ERROR_NOT_AUTHENTICATED,
  fetchApiNotAuthenticatedMiddleware,
  fetchErrorMiddleware,
  fetchErrorMiddlewareAllowedErrors
} from 'middleware/fetchErrorMiddleware';
import { FAILED, UPLOAD_SHOPPABLE_VIDEO, UPLOADED } from 'constants/shoppablePosts';
import timedFetch from 'middleware/timedFetch';
import { selectMafiaConfig, selectZcsConfig } from 'selectors/environment';
import {
  addInfluencerSocialProfile,
  enrollInfluencer,
  fetchScrutinizerScanStatus,
  getConfirmDeclineStatus,
  getConfirmPendingStatus,
  getInfluencerAppConfigurations,
  getInfluencerCollectionReport,
  getInfluencerDetails,
  getInfluencerDetailsByIdentifier,
  getInfluencerDetailsByIdentifierOnServer,
  getInfluencerProductReport,
  getInfluencerStatus,
  getInfluencerStyles,
  getInfluencerSummaryReport,
  getInfluencerToken,
  getPresignedUrl,
  getVideoFromVMS,
  initiateScrutinizerScanRequest,
  updateInfluencerDetails,
  updateInfluencerSocialProfile,
  uploadToMediaCentral,
  uploadToVMS,
  validateProfileHandle
} from 'apis/mafia/influencer';

export function enrollSuccess(response) {
  track(() => [evEnrollInfluencer, {}]);
  return {
    type: ENROLL_INFLUENCER_SUCCESS,
    response
  };
}

export function setIsInfluencer(response) {
  return {
    type: SET_IS_INFLUENCER_STATUS,
    status: response.status
  };
}

export function setShowInfluencerHub(response) {
  return {
    type: SET_SHOW_INFLUENCER_HUB,
    status: response.status
  };
}

export function setAppConfiguration(response) {
  return {
    type: SET_INFLUENCER_APP_CONFIG,
    appConfig: response
  };
}

export function setInfluencerDetails(response) {
  convertTimestampToDays(response);
  return {
    type: SET_INFLUENCER_DETAILS,
    influencerDetails: response
  };
}

export function setInfluencerStyles(stylesList) {
  return {
    type: SET_INFLUENCER_STYLES,
    stylesList
  };
}

export function setInfluencerBio(profileBio) {
  return {
    type: SET_INFLUENCER_BIO,
    profileBio
  };
}

export function setInfluencerName(name) {
  return {
    type: SET_INFLUENCER_NAME,
    name
  };
}

export function setDraft(draft) {
  return {
    type: SET_INFLUENCER_DRAFT,
    draft
  };
}

export function toggleSaveDraft(canSave) {
  return {
    type: TOGGLE_INFLUENCER_SAVE_DRAFT,
    canSave
  };
}

export function setInfluencerProfileHandle(profileHandle) {
  return {
    type: SET_INFLUENCER_PROFILE_HANDLE,
    profileHandle
  };
}

export function requestInfluencerDetails() {
  return { type: REQUEST_INFLUENCER_DETAILS };
}

export function setInfluencerToken(response) {
  return {
    type: SET_INFLUENCER_TOKEN,
    influencerToken: response.shareToken
  };
}

export function setInfluencerReport(response, displayReport) {
  return {
    type: SET_INFLUENCER_REPORT,
    report: response,
    displayReport
  };
}

export function setInfluencerCollectionReport(response, displayReport, page, pageLength) {
  return {
    type: SET_INFLUENCER_COLLECTION_REPORT,
    collectionReport: response,
    currentCollectionReportPage: page,
    totalCollectionReportPages: Math.ceil((response.totalRecords || 0) / pageLength),
    displayCollectionReport: displayReport
  };
}

export function setInfluencerProductReport(response, displayReport, page, pageLength) {
  return {
    type: SET_INFLUENCER_PRODUCT_REPORT,
    productReport: response,
    currentProductReportPage: page,
    totalProductReportPages: Math.ceil((response.totalRecords || 0) / pageLength),
    displayProductReport: displayReport
  };
}

export function setInfluencerSummaryReport(response, displayReport) {
  return {
    type: SET_INFLUENCER_SUMMARY_REPORT,
    summaryReport: response,
    displaySummaryReport: displayReport
  };
}

export function setInfluencerReportView(reportView) {
  return {
    type: SET_INFLUENCER_REPORT_VIEW,
    reportView
  };
}

export function setSymphonySlots(response) {
  return {
    type: SET_INFLUENCER_SYMPHONY_SLOTS,
    data: response
  };
}

export function setInfluencerCollectionBanner(showCollectionBanner) {
  return {
    type: SET_INFLUENCER_COLLECTION_BANNER,
    showCollectionBanner
  };
}

export function setShoppablePostCreationSource(postCreationSource) {
  return {
    type: SET_SHOPPABLE_POST_CREATION_SOURCE,
    postCreationSource
  };
}

export function setIsReportLoading(isReportLoading) {
  return {
    type: SET_IS_INFLUENCER_REPORT_LOADING,
    isReportLoading
  };
}

export function setNewCollectionCreated(isNewCollectionCreated) {
  return {
    type: SET_NEW_COLLECTION_CREATED,
    isNewCollectionCreated
  };
}

export function clearInfluencerReports() {
  return {
    type: CLEAR_INFLUENCER_REPORTS
  };
}

export function updateInfluencerCollectionBanner(showCollectionBanner) {
  return dispatch => {
    dispatch(setInfluencerCollectionBanner(showCollectionBanner));
  };
}

export function setInfluencerReportDuration(duration) {
  return {
    type: SET_INFLUENCER_REPORT_PERIOD_SELECTION,
    duration
  };
}

export function setDocMetaForShoppablePost(shoppablePost) {
  return {
    type: SET_DOC_META_SHOPPABLE_POST,
    shoppablePost
  };
}

export function setDocMetaForInfluencerProfile(profile) {
  return {
    type: SET_DOC_META_STOREFRONT,
    profile
  };
}

export function setDocMetaForStyleFeed(styleFeed) {
  return {
    type: SET_DOC_META_STYLE_FEED,
    styleFeed
  };
}

export function handleEnrollInfluencer(requestBody) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);
    const isCustomer = 'x-main' in cookies;

    if (!isCustomer) {
      return dispatch(redirectTo(INFLUENCER_SIGNUP_PAGE));
    }

    return getInfluencerStatus(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        if (response.status !== null) {
          return dispatch(redirectTo(INFLUENCER_ELIGIBILITY_PAGE));
        }
        return enrollInfluencer(mafiaConfig, { data: requestBody }, cookies)
          .then(fetchErrorMiddleware)
          .then(response => {
            const path = response.status === 'Success' ? INFLUENCER_ELIGIBILITY_PAGE : INFLUENCER_SIGNUP_PAGE;
            dispatch(enrollSuccess(response));
            dispatch(redirectTo(path));
          });
      });
  };
}

export function handleGetInfluencerStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerStatus(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(setIsInfluencer(response));
        dispatch(setShowInfluencerHub(response));
      });
  };
}

export function handleGetInfluencerAppsConfig() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerAppConfigurations(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(setAppConfiguration(response));
      });
  };
}

export function handleGetInfluencerDetails() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerDetails(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(setInfluencerDetails(response));
      });
  };
}

export function handleGetInfluencerDetailsByIdentifier(
  identifier,
  identifierType = IDENTIFIER_TYPE_PROFILE_HANDLE,
  host = '',
  fetchOnServer = false
) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const getDetails = fetchOnServer ? getInfluencerDetailsByIdentifierOnServer : getInfluencerDetailsByIdentifier;

    return getDetails(mafiaConfig, identifier, identifierType, cookies, host)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(setInfluencerDetails(response));
        dispatch(setIsInfluencer(response.details));
        dispatch(setShowInfluencerHub(response.details));
      })
      .catch(e => {
        dispatch(setError(e.statusText, null, e.status));
      });
  };
}

export function handleGetConfirmPendingStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getConfirmPendingStatus(mafiaConfig, cookies).then(fetchErrorMiddleware);
  };
}

export function handleGetConfirmDeclineStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getConfirmDeclineStatus(mafiaConfig, cookies).then(fetchErrorMiddleware);
  };
}

export function handleGetInfluencerToken() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies, influencer } = state;
    const mafiaConfig = selectMafiaConfig(state);
    const isCustomer = 'x-main' in cookies;
    // Fetch influencer token only once.
    if (!isCustomer || influencer.influencerToken !== '') {
      return;
    }

    if (influencer.status === InfluencerStatus.UNKNOWN) {
      return getInfluencerStatus(mafiaConfig, cookies)
        .then(fetchErrorMiddleware)
        .then(response => {
          dispatch(setIsInfluencer(response));
          if (response.status === InfluencerStatus.ACTIVE) {
            return getInfluencerToken(mafiaConfig, null, cookies)
              .then(fetchErrorMiddleware)
              .then(response => dispatch(setInfluencerToken(response)));
          }
        });
    } else if (influencer.status === InfluencerStatus.ACTIVE) {
      return getInfluencerToken(mafiaConfig, null, cookies)
        .then(fetchErrorMiddleware)
        .then(response => dispatch(setInfluencerToken(response)));
    }
  };
}

export function handleProfileCallback(searchParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerStatus(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        const queryParams = queryString.parse(searchParams) || {};
        const isFirstEnrolled = response.status !== InfluencerStatus.ACTIVE;
        const { error, code, state } = queryParams || {};
        if (error) {
          dispatch(redirectTo(INFLUENCER_ELIGIBILITY_PAGE));
        }
        if (code && state) {
          const stateVal = state.toUpperCase();
          const profileType = ProfileType[stateVal] || '';
          const oAuthCode = code;
          if (oAuthCode && profileType) {
            const requestBody = {
              oAuthCode: oAuthCode,
              profileType
            };
            dispatch(handleAddSocialProfile(requestBody, isFirstEnrolled));
          }
        }
      });
  };
}

export function handleAddSocialProfile(requestBody, isFirstEnroll) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return addInfluencerSocialProfile(mafiaConfig, { data: requestBody }, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        if (response.status === 'Done') {
          if (!isFirstEnroll) {
            return dispatch(redirectTo(INFLUENCER_HUB_PAGE));
          } else {
            return getInfluencerDetails(mafiaConfig, cookies)
              .then(fetchErrorMiddleware)
              .then(response => {
                dispatch(setInfluencerDetails(response));
                const redirectUrl =
                  response.details.status === InfluencerStatus.PENDING ? INFLUENCER_ENROLL_PENDING : INFLUENCER_ENROLL_SUCCESS_PAGE;

                return dispatch(redirectTo(redirectUrl));
              });
          }
        } else {
          const redirectUrl = isFirstEnroll ? INFLUENCER_ELIGIBILITY_PAGE : INFLUENCER_HUB_PAGE;
          return dispatch(redirectTo(redirectUrl));
        }
      });
  };
}

export function handleUpdateSocialProfile(requestBody) {
  return (_dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return updateInfluencerSocialProfile(mafiaConfig, { data: requestBody }, cookies).then(fetchErrorMiddleware);
  };
}

export function handleUpdateInfluencerDetails(requestBody) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return updateInfluencerDetails(mafiaConfig, { data: requestBody }, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        if (response.status === 'Success') {
          dispatch(handleGetInfluencerDetails());
          dispatch(redirectTo(INFLUENCER_HUB_PAGE));
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not save Influencer profile details.', e);
        dispatch(handleGetInfluencerDetails());
      });
  };
}

export function handleValidateProfileHandle(profileHandle) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return validateProfileHandle(mafiaConfig, profileHandle, cookies)
      .then(fetchErrorMiddleware)
      .then(response => response?.isValid)
      .catch(e => {
        trackError('NON-FATAL', 'Could not validate Influencer profile handle.', e);
      });
  };
}

export function fetchAvailableStyles() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerStyles(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => response?.styles)
      .catch(e => {
        trackError('NON-FATAL', 'Could not fetch available styles for influencer.', e);
      });
  };
}

export function fetchIsInfluencer() {
  return function (dispatch, getState) {
    const { influencer } = getState();

    if (influencer.status === InfluencerStatus.UNKNOWN) {
      return dispatch(handleGetInfluencerStatus());
    }
  };
}

export function fetchInfluencerAppConfig() {
  return function (dispatch, getState) {
    const { influencer } = getState();
    if (!influencer.appConfig) {
      return dispatch(handleGetInfluencerAppsConfig());
    }
  };
}

function handlePendingAndDeclineProfiles(socialMediaProfiles, isPendingConfirmed, isDeclineConfirmed, pathname, dispatch) {
  const pendingProfiles = filterProfiles(socialMediaProfiles, ProfileValidationStatus.PENDING);
  const declinedProfiles = filterProfiles(socialMediaProfiles, ProfileValidationStatus.DECLINED);
  if (declinedProfiles.length > 0 && !isDeclineConfirmed) {
    pathname === INFLUENCER_ENROLL_DECLINE && dispatch(handleGetConfirmDeclineStatus());
    return dispatch(redirectTo(INFLUENCER_ENROLL_DECLINE));
  } else if (pendingProfiles.length > 0 && !isPendingConfirmed) {
    pathname === INFLUENCER_ENROLL_PENDING && dispatch(handleGetConfirmPendingStatus());
    return dispatch(redirectTo(INFLUENCER_ENROLL_PENDING));
  } else {
    return dispatch(redirectTo(INFLUENCER_HUB_PAGE));
  }
}

function handleRedirectForNonNullStatus(mafiaConfig, cookies, dispatch, isLoading, applyWithOtherAccount, location) {
  const { pathname } = location || {};

  return getInfluencerDetails(mafiaConfig, cookies)
    .then(fetchErrorMiddleware)
    .then(response => {
      dispatch(setInfluencerDetails(response));
      const { details: { socialMediaProfiles, profileHandle, status, isDeclineConfirmed, isPendingConfirmed } = {} } = response;
      if (status !== InfluencerStatus.UNKNOWN && !isLoading) {
        switch (status) {
          case InfluencerStatus.ACTIVE:
            return profileHandle?.length ? dispatch(redirectTo(INFLUENCER_HUB_PAGE)) : dispatch(redirectTo(INFLUENCER_ENROLL_SUCCESS_PAGE));
          case InfluencerStatus.PENDING:
            if (!socialMediaProfiles || socialMediaProfiles.length === 0) {
              dispatch(redirectTo(INFLUENCER_ELIGIBILITY_PAGE));
            } else {
              return handlePendingAndDeclineProfiles(socialMediaProfiles, isPendingConfirmed, isDeclineConfirmed, pathname, dispatch);
            }
            break;
          default:
            return dispatch(redirectTo(INFLUENCER_LANDING_PAGE));
        }
      }
    });
}

export function convertTimestampToDays(response) {
  let { details } = response;
  if (details) {
    const creationTimestamp = details.creationDate;
    const currentTimestamp = Date.now();
    const days = Math.floor((currentTimestamp - creationTimestamp) / 86400000); // 1000 * 3600 * 24
    details.influencerCreationDays = days;
  } else {
    details = {};
    details.influencerCreationDays = 0;
  }
}

export function fetchInfluencerSummaryReport(timePeriod = LAST_7_DAYS.value) {
  return (dispatch, getState) => {
    dispatch(setIsReportLoading(true));

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerSummaryReport(mafiaConfig, cookies, timePeriod)
      .then(response => response.json())
      .then(response => {
        let displayReport = true;
        if (response.message?.includes(REPORT_TOGGLE_OFF_ERROR)) {
          displayReport = false;
        }
        dispatch(setInfluencerSummaryReport(response, displayReport));
        return dispatch(setIsReportLoading(false));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not fetch Influencer Summary Report.', e);
        dispatch(setApiStatus(FETCH_INFLUENCER_SUMMARY_REPORT, FAILED));
        return dispatch(setIsReportLoading(false));
      });
  };
}

export function fetchInfluencerProductReport(timePeriod = LAST_7_DAYS.value, page = 1, pageLength = 5) {
  return (dispatch, getState) => {
    dispatch(setIsReportLoading(true));

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerProductReport(mafiaConfig, cookies, timePeriod, page, pageLength)
      .then(response => response.json())
      .then(response => {
        let displayReport = true;
        if (response.message?.includes(REPORT_TOGGLE_OFF_ERROR)) {
          displayReport = false;
        }
        dispatch(setInfluencerProductReport(response, displayReport, page, pageLength));
        return dispatch(setIsReportLoading(false));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not fetch Influencer Product Report.', e);
        dispatch(setApiStatus(FETCH_INFLUENCER_PRODUCT_REPORT, FAILED));
        return dispatch(setIsReportLoading(false));
      });
  };
}

export function fetchInfluencerCollectionReport(timePeriod = LAST_7_DAYS.value, page = 1, pageLength = 5) {
  return (dispatch, getState) => {
    dispatch(setIsReportLoading(true));

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerCollectionReport(mafiaConfig, cookies, timePeriod, page, pageLength)
      .then(response => response.json())
      .then(response => {
        let displayReport = true;
        if (response.message?.includes(REPORT_TOGGLE_OFF_ERROR)) {
          displayReport = false;
        }
        dispatch(setInfluencerCollectionReport(response, displayReport, page, pageLength));
        return dispatch(setIsReportLoading(false));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not fetch Influencer Collection Report.', e);
        dispatch(setApiStatus(FETCH_INFLUENCER_COLLECTION_REPORT, FAILED));
        return dispatch(setIsReportLoading(false));
      });
  };
}

export function updateReportView(reportView) {
  return dispatch => {
    dispatch(setInfluencerReportView(reportView));
  };
}

export function redirectBasedOnInfluencerDetails() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location },
      cookies,
      influencer: { isLoading, applyWithOtherAccount }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getInfluencerStatus(mafiaConfig, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        if (response.status === null) {
          dispatch(setIsInfluencer(response));
          return dispatch(redirectTo(INFLUENCER_LANDING_PAGE));
        } else {
          return handleRedirectForNonNullStatus(mafiaConfig, cookies, dispatch, isLoading, applyWithOtherAccount, location);
        }
      });
  };
}

export const fetchCollectionCreatives =
  (getCollectionCreatives = getLandingPageInfo) =>
  (dispatch, getState) => {
    const state = getState();
    const { pageView: cookies } = getState();
    const zcsConfig = selectZcsConfig(state);
    const pageName = 'influencer-collections-creatives';

    return getCollectionCreatives(zcsConfig, { pageName }, cookies)
      .then(fetchErrorMiddleware)
      .then(data => {
        if (data) {
          dispatch(setSymphonySlots(data));
        }
      });
  };

export function loadInfluencerLandingPage(pageName) {
  return function (dispatch, getState) {
    return dispatch(fetchLandingPageInfo(pageName)).then(() => {
      const {
        landingPage: { pageName, pageInfo }
      } = getState();
      if (pageInfo) {
        dispatch(loadedLandingPage(pageName, pageInfo));
      }
    });
  };
}

export function loadedLandingPage(pageName, pageInfo) {
  if (pageName === 'influencer-home') {
    track(() => [evLandingPageInfluencer, { pageId: INFLUENCER_HOME_PAGE }]);
  }
  return {
    type: SET_DOC_META_INFLUENCER_LP,
    pageName,
    pageInfo
  };
}

export function uploadInfluencerImage(
  file,
  fileExtension,
  contentId,
  mediaType,
  mediaSequenceNo = 0,
  cookies,
  mafiaConfig,
  location,
  dispatch,
  fetcher = timedFetch('PutInfluencerMedia')
) {
  let getObjectPreSignedUrl, putObjectPreSignedUrl;
  const returnTo = INFLUENCER_HUB_PAGE;

  return getPresignedUrl(mafiaConfig, { contentId, fileExtension }, cookies)
    .then(fetchApiNotAuthenticatedMiddleware)
    .then(fetchErrorMiddleware)
    .then(response => {
      ({ putObjectPreSignedUrl, getObjectPreSignedUrl } = response);
      return fetcher(putObjectPreSignedUrl, {
        method: 'PUT',
        body: file
      });
    })
    .then(() => {
      const scrutinizerInitiateScanRequest = {
        preSignedUrl: getObjectPreSignedUrl
      };
      return initiateScrutinizerScanRequest(mafiaConfig, scrutinizerInitiateScanRequest, cookies)
        .then(fetchErrorMiddleware)
        .then(response => {
          const { scanRequestId } = response;
          return pollScrutinizerScanStatusTillProcessed(mafiaConfig, scanRequestId, cookies);
        });
    })
    .then(response => {
      if (response !== ScrutinizerScanState.SUCCESS) {
        return { scrutinizerFailed: true };
      }
      const mediaCentralRequestBody = {
        contentId,
        mediaType,
        preSignedUrl: getObjectPreSignedUrl,
        mediaSequenceNo
      };
      return uploadToMediaCentral(mafiaConfig, mediaCentralRequestBody, cookies).then(fetchErrorMiddleware);
    })
    .catch(e => {
      if (e.id !== ERROR_NOT_AUTHENTICATED) {
        trackError('NON-FATAL', 'Not able to upload Influencer Image', e);
      } else if (returnTo) {
        return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(returnTo, location))(e);
      }
    });
}

export function uploadInfluencerVideo(
  file,
  fileExtension,
  contentId,
  cookies,
  mafiaConfig,
  location,
  dispatch,
  fetcher = timedFetch('PutInfluencerMedia')
) {
  let getObjectPreSignedUrl, putObjectPreSignedUrl;
  const returnTo = INFLUENCER_HUB_PAGE;

  return getPresignedUrl(mafiaConfig, { contentId, fileExtension }, cookies)
    .then(fetchApiNotAuthenticatedMiddleware)
    .then(fetchErrorMiddleware)
    .then(response => {
      ({ putObjectPreSignedUrl, getObjectPreSignedUrl } = response);
      return fetcher(putObjectPreSignedUrl, {
        method: 'PUT',
        body: file
      });
    })
    .then(() => {
      const scrutinizerInitiateScanRequest = {
        preSignedUrl: getObjectPreSignedUrl
      };
      return initiateScrutinizerScanRequest(mafiaConfig, scrutinizerInitiateScanRequest, cookies)
        .then(fetchErrorMiddleware)
        .then(response => {
          const { scanRequestId } = response;
          return pollScrutinizerScanStatusTillProcessed(mafiaConfig, scanRequestId, cookies);
        });
    })
    .then(response => {
      if (response !== ScrutinizerScanState.SUCCESS) {
        return { scrutinizerFailed: true };
      }
      dispatch(setApiStatus(UPLOAD_SHOPPABLE_VIDEO, UPLOADED));
      const s3Key = getS3KeyForVideoUpload(getObjectPreSignedUrl);
      return uploadToVMS(mafiaConfig, { contentId, s3Key }, cookies)
        .then(fetchErrorMiddleware)
        .then(response => {
          const { mediaId } = response;
          return pollForVideoStatusTillProcessed(mafiaConfig, mediaId, cookies);
        });
    })
    .catch(e => {
      if (e.id !== ERROR_NOT_AUTHENTICATED) {
        trackError('NON-FATAL', 'Not able to upload Influencer Image', e);
      } else if (returnTo) {
        return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(returnTo, location))(e);
      }
    });
}

export function createNewCollection() {
  return dispatch => {
    dispatch(setNewCollectionCreated(true));
  };
}

const pollForVideoStatusTillProcessed = async (mafiaConfig, mediaId, cookies) => {
  let videoState = VideoState.CREATED;
  let videoDetails;
  while (videoState === VideoState.CREATED) {
    await getVideoFromVMS(mafiaConfig, mediaId, cookies)
      .then(fetchErrorMiddlewareAllowedErrors([500], { 500: res => ({ error: true, res }) }))
      .then(async response => {
        if (response.error === true) {
          videoState = VideoState.FAILURE;
        } else {
          videoDetails = response;
          ({ videoState } = videoDetails);
          if (videoState === VideoState.CREATED) {
            await sleep(VIDEO_GET_API_POLL_DELAY);
          }
        }
      });
  }
  return { ...videoDetails, mediaId };
};

const pollScrutinizerScanStatusTillProcessed = async (mafiaConfig, scanRequestId, cookies) => {
  let scanStatus = ScrutinizerScanState.IN_PROGRESS;

  while (scanStatus === ScrutinizerScanState.IN_PROGRESS) {
    await fetchScrutinizerScanStatus(mafiaConfig, scanRequestId, cookies)
      .then(fetchErrorMiddlewareAllowedErrors([500], { 500: res => ({ error: true, res }) }))
      .then(async response => {
        if (response.error === true) {
          scanStatus = ScrutinizerScanState.FAILURE;
        } else {
          ({ scanStatus: scanStatus } = response);
          if (scanStatus === ScrutinizerScanState.IN_PROGRESS) {
            await sleep(SCRUTINIZER_SCAN_STATUS_GET_API_POLL_DELAY);
          }
        }
      });
  }
  return scanStatus;
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
