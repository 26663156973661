import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import useWindowSize from 'hooks/useWindowSize';
import useEffectOnce from 'hooks/useEffectOnce';
import { selectPageType } from 'selectors/pageView';
import { loadFromLocalStorage, saveToLocalStorage } from 'helpers/localStorageUtilities';
import { EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY } from 'constants/appConstants';
import { getViewportAsString } from 'utils/viewportUtil';
import type { EmailList } from 'types/subscriptions';
import { ADD_TO_CART_BUTTON_ID } from 'constants/addToCart';
import { DESKTOP_MAXIMIZED_MOBILE_MINIMIZED, DESKTOP_MOBILE_MAXIMIZED, DESKTOP_MOBILE_MINIMIZED } from 'constants/emailSignupDrawer';

interface SavedState {
  isDrawerVisible: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
  isMinimizedShown: boolean;
  shouldRenderMinimized: boolean;
  isInitialRun: boolean;
  isDismissed: boolean;
}

const useEmailSignupDrawerState = (isCustomer: boolean, emailLists: EmailList[], isMobile: boolean, variantVersion: string) => {
  const { width = 0 } = useWindowSize(200);
  const pageType = useSelector(selectPageType);
  const [isMounted, setIsMounted] = useState(false);
  const [isInitialRun, setInitialRun] = useState<boolean>(() => {
    const savedState: SavedState | null = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY);
    return savedState ? savedState.isInitialRun : true;
  });
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState<string>('Please Enter A Valid Email Address');
  const [isFirstTimeSubscriber, setIsFirstTimeSubscriber] = useState<boolean>(true);
  const [isDismissed, setIsDismissed] = useState<boolean>(() => {
    const savedState: SavedState | null = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY);
    return savedState ? savedState.isDismissed : false;
  });
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(() => {
    const savedState: SavedState | null = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY);
    return savedState ? savedState.isDrawerVisible : false;
  });
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isMinimizedShown, setIsMinimizedShown] = useState<boolean>(() => {
    const savedState: SavedState | null = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY);
    return savedState ? savedState.isMinimizedShown : false;
  });
  const [shouldRenderMinimized, setShouldRenderMinimized] = useState<boolean>(() => {
    const savedState: SavedState | null = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY);
    return savedState ? savedState.shouldRenderMinimized : false;
  });
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(() => {
    const savedState: SavedState | null = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY);
    return savedState ? savedState.isSubmitted : false;
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAllowedLocation, setIsAllowedLocation] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  const validCustomer = isCustomer && !isSubscribed;
  const customerSubscriptionStatus = validCustomer || !isCustomer;
  const productPage = pageType === 'product';
  const clientType = getViewportAsString(width);
  const isTablet = clientType === 'tablet';

  const checkForSubscription = (subscriptionsInfo: EmailList[]): boolean => {
    const emailList = (subscriptionsInfo && subscriptionsInfo.find(item => item.emailListId === '1')) || { subscribed: false };
    return emailList.subscribed;
  };

  useEffectOnce(() => {
    setIsMounted(true);
  });

  useEffect(() => {
    const allowedPages = ['homepage', 'landing', 'search', 'product'];
    setIsAllowedLocation(allowedPages.includes(pageType));
  }, [pageType]);

  useEffect(() => {
    if (isMounted && isCustomer) {
      setIsSubscribed(checkForSubscription(emailLists));
    }
  }, [isCustomer, emailLists]);

  useEffect(() => {
    const shouldEnable = customerSubscriptionStatus && isAllowedLocation;
    const debouncedEnable = debounce(() => {
      setIsEnabled(shouldEnable);
    }, 300);

    debouncedEnable();

    return () => {
      debouncedEnable.cancel();
    };
  }, [customerSubscriptionStatus, isAllowedLocation]);

  useEffect(() => {
    const handleDrawerVisibility = debounce(() => {
      if (!isMounted || !isEnabled || isDismissed) return;

      const savedState = loadFromLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY) || {};
      if (savedState.isSubmitted) {
        setIsSubmitted(savedState.isSubmitted);
      }
      if (savedState.isInitialRun === false) {
        return;
      }

      const newTimer = setTimeout(() => {
        if (isEnabled && !isSubmitted && !isMinimizedShown) {
          setIsDrawerVisible(true);
          saveToLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY, {
            isDrawerVisible,
            isSubmitting,
            isSubmitted,
            isMinimizedShown,
            shouldRenderMinimized,
            isInitialRun: false,
            isDismissed
          });
          setInitialRun(false);

          switch (variantVersion) {
            case DESKTOP_MAXIMIZED_MOBILE_MINIMIZED:
              if (isMobile) {
                setIsMinimizedShown(true);
                setShouldRenderMinimized(true);
              } else {
                setIsOpen(true);
              }
              break;
            case DESKTOP_MOBILE_MAXIMIZED:
              setIsOpen(true);
              break;
            case DESKTOP_MOBILE_MINIMIZED:
              setIsMinimizedShown(true);
              setShouldRenderMinimized(true);
              setIsOpen(false);
              break;
          }
        }
      }, 5000);

      setTimer(newTimer);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, 300);

    handleDrawerVisibility();

    return () => {
      handleDrawerVisibility.cancel();
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isEnabled, isSubmitted, pageType, customerSubscriptionStatus]);

  const checkIfAddToCartButtonVisibleAndAtBottom = () => {
    const addToCartButton = document.getElementById(ADD_TO_CART_BUTTON_ID);

    if (addToCartButton) {
      const addToCartButtonRect = addToCartButton.getBoundingClientRect();
      const isAddToCartAtBottom = addToCartButtonRect.bottom >= window.innerHeight;

      return isAddToCartAtBottom;
    }

    return false;
  };

  const checkDrawerVisibility = useCallback(
    debounce(() => {
      if (!isMounted) return;
      setShouldRenderMinimized(true);
      let isAddToCartButtonAtBottom;

      if (productPage) {
        isAddToCartButtonAtBottom = checkIfAddToCartButtonVisibleAndAtBottom();
      }
      const distanceFromBottom: number = document.documentElement.scrollHeight - window.innerHeight - window.scrollY;

      switch (true) {
        case distanceFromBottom < 650 && !isMobile && !isSubmitted:
          setIsMinimizedShown(false);
          break;
        case distanceFromBottom > 650 && !isMobile && !isSubmitted:
          setIsMinimizedShown(true);
          break;
        case (distanceFromBottom < 550 && (isMobile || isTablet) && !isSubmitted) || (isAddToCartButtonAtBottom && productPage):
          setIsMinimizedShown(false);
          break;
        case (distanceFromBottom > 550 && (isMobile || isTablet) && !isSubmitted) || (!isAddToCartButtonAtBottom && productPage): // specific tablet check needed for safari
          setIsMinimizedShown(true);
          break;
        default:
          break;
      }
    }, 0),
    [isMounted, isSubmitted, pageType]
  );

  useEffect(() => {
    if (!isAllowedLocation) {
      setShouldRenderMinimized(false);
    }
  }, [isAllowedLocation, pageType, shouldRenderMinimized]);

  useEffect(() => {
    if (isDismissed) return;

    if (isMounted && !isInitialRun && isAllowedLocation) {
      window.addEventListener('scroll', checkDrawerVisibility);

      // Check visibility whenever isAllowedLocation changes
      checkDrawerVisibility();
    }

    return () => {
      window.removeEventListener('scroll', checkDrawerVisibility);
    };
  }, [isMounted, pageType, isAllowedLocation, isInitialRun]);

  useEffect(() => {
    saveToLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY, {
      isDrawerVisible,
      isSubmitting,
      isSubmitted,
      isMinimizedShown,
      shouldRenderMinimized,
      isInitialRun,
      isDismissed
    });
  }, [isDrawerVisible, isSubmitting, isSubmitted, isMinimizedShown, shouldRenderMinimized, isInitialRun, isDismissed]);

  return {
    isAccepted,
    setIsAccepted,
    isEmailValid,
    setIsEmailValid,
    invalidEmailMsg,
    setInvalidEmailMsg,
    emailAddress,
    setEmailAddress,
    isDrawerVisible,
    setIsDrawerVisible,
    isFocused,
    setIsFocused,
    isMinimizedShown,
    setIsMinimizedShown,
    isSubmitting,
    setIsSubmitting,
    isSubmitted,
    setIsSubmitted,
    isOpen,
    setIsOpen,
    isFirstTimeSubscriber,
    setIsFirstTimeSubscriber,
    shouldRenderMinimized,
    setShouldRenderMinimized,
    isDismissed,
    setIsDismissed
  };
};

export default useEmailSignupDrawerState;
